@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-loader:disabled::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid;
    border-top-color: white;
    -webkit-animation: loader 0.8s linear infinite;
    animation: loader 0.8s linear infinite;
  }

  .btn-loader:disabled {
    position: relative;
    color: var(--primary-color) !important;
  }
}

:root {
  --primary-color: #0c78f1;
  --secondary-color: #0d3060;
  --gray: #7a88a0;
}

html {
  font-size: 62.5%;
}
a:hover {
  text-decoration: none;
  /* color: #fff; */
}
.top-nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-link {
  display: flex;
  gap: 30px;
  align-items: center;
}
/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 1.6rem;
  font-family: Plus Jakarta Sans;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: var(--primary-color);
}

button:disabled {
  cursor: not-allowed;
}

.popup-content {
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 33px 5px rgb(22 49 96 / 19%);
  margin: 10px;
}

.popup-option {
  padding: 8px 15px;
  font-weight: 400;
  font-size: 1.4rem;
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 5px;
  align-items: center;
}

/* .popup-option:hover { */
/* background-color: var(--primary-color); */
/* backdrop-filter: blur(10px); */
/* cursor: pointer; */
/* color: #fff; */
/* } */

.dot {
  background-image: url("./assets/images/dots-home-banner.svg");
  background-position: top right;
  background-repeat: no-repeat;
  height: calc(800px - 100px);
  margin-top: -200px;
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
}

.product-tab {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.6rem;
  font-weight: 500;
  margin-right: 10px;
  transition: background-color 0.3s ease-in-out;
}

.active-tab {
  background-color: #007bff;
  color: white;
}
.mobile {
  display: none;
}
.top-nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-link {
  display: flex;
  gap: 30px;
  align-items: center;
}

.opacity-transition {
  transition: opacity 0.5s ease-in-out;
}

.privacy-list ol {
  margin-top: 20px;
  counter-reset: item;
  padding-left: 24px;
}
.swal2-popup.swal2-toast .swal2-title {
  font-size: 1.4rem !important;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1023px) {
  .dot {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .web-nav {
    display: none;
  }
  .mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 30px 0 10px;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 56.25%;
  }
  .classcube-type-container-main {
    display: block;
    margin-bottom: 50px;
  }
  .classcube-type-container {
    /* color: #0C78F1; */
    width: 100%;
    min-height: 250px;
    margin-bottom: 20px;
  }
  .classcube-type-text {
    max-width: 100%;
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }
}
